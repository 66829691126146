import React, { createContext, memo, useEffect, useState } from 'react';
import i18next from 'i18next';
import themeConfig from '../data/themeConfig';

const languageStorageItemKey = 'language';

const defaultState = {
  theme: 'Dark',
  setTheme: () => {},
  language: 'en',
  setLanguage: () => {},
  isSideBarOpen: true,
  setSideBarToggle: () => {},
};

const SettingsContext = createContext(defaultState);

const SettingsProvider = ({ children }) => {
  const hashUrl = "/page-data/app-data.json";
  const currentHash = localStorage.getItem("build") || null;
  const [theme, setTheme] = useState(defaultState.theme);
  const [language, setLanguage] = useState(defaultState.theme);
  const [isSideBarOpen, setSideBarToggle] = useState(
    defaultState.isSideBarOpen,
  );

  const clearCache = () => {
    [
      "boardsi-document-builder-cache-precache-v2-https://document.boardsi.com/",
      "boardsi-document-builder-cache-runtime-https://document.boardsi.com/",
      "gatsby-plugin-offline-precaching-v2-https://document.boardsi.com/",
      "gatsby-plugin-offline-runtime-https://document.boardsi.com/"
    ].forEach((cacheName)=>{
      caches.open(cacheName).then((cache)=>{
        cache.keys().then((key)=>{
          key.forEach((k)=>{
            cache.delete(k).then((result)=>{console.log(result)});
          })
        })
      });
    });
  }

  useEffect(() => {
    if(currentHash == null) {
      fetch(hashUrl)
        .then(response => response.json())
        .then(data => {
          localStorage.setItem("build", data.webpackCompilationHash);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      fetch(hashUrl)
        .then(response => response.json())
        .then(data => {
          if(data.webpackCompilationHash != currentHash) { // new
            localStorage.setItem("build", data.webpackCompilationHash);
            new Promise((resolve, _)=>{
              resolve(clearCache());
            }).then(()=>{console.log('cache cleared')});
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    const prefTheme = localStorage.getItem('theme') || defaultState.theme;
    const prefLanguage =
      localStorage.getItem(languageStorageItemKey) || defaultState.language;
    setTheme(prefTheme);
    setLanguage(prefLanguage);
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    const colorConfig = themeConfig[theme];
    for (const [key, value] of Object.entries(colorConfig)) {
      document.documentElement.style.setProperty(key, value);
    }
  }, [theme]);

  useEffect(() => {
    localStorage.setItem(languageStorageItemKey, language);
    i18next.changeLanguage(language);
  }, [language]);

  return (
    <SettingsContext.Provider
      value={{
        theme,
        setTheme,
        language,
        setLanguage,
        isSideBarOpen,
        setSideBarToggle,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

const memoizedProvider = memo(SettingsProvider);

export { memoizedProvider as SettingsProvider, languageStorageItemKey };
