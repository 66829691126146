import React, { memo, useEffect, useState } from 'react'
import { AuthorizerProvider } from '@authorizerdev/authorizer-react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import {useAuthorizer} from '@authorizerdev/authorizer-react';
import * as Sentry from "@sentry/gatsby";
import { BrowserTracing } from "@sentry/tracing";

var instance = null;

const Apples = ({children, ...props}) => {
    const {user, loading, token} = useAuthorizer();

      Sentry.init({
        dsn: "https://73fbf3ffda8445d081c61aa803d69d4e@errors.boardsi.com/3",
        integrations: [
            new BrowserTracing(), 
        ],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
      
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      });

    let instanceConfig = {
        urlBase: 'https://analytics.boardsi.com/',
        siteId: 2,
        userId: undefined, // optional, default value: `undefined`.
        trackerUrl: 'https://analytics.boardsi.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
        srcUrl: 'https://analytics.boardsi.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
        disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
        heartBeat: { // optional, enabled by default
          active: true, // optional, default value: true
          seconds: 10 // optional, default value: `15
        },
        linkTracking: false, // optional, default value: true
        configurations: { // optional, default value: {}
          // any valid matomo configuration, all below are optional
          disableCookies: false,
          setSecureCookie: true,
          setRequestMethod: 'POST'
        }
      }

    useEffect(() => {
        if ( typeof token !== 'undefined' && token !== null && 'access_token' in token && token.access_token !== null && !loading ) {
            instanceConfig.userId = user.email;
            instance = createInstance(instanceConfig);

            Sentry.configureScope(function(scope) {
                scope.setTag("platform", "my value");
                scope.setUser({
                  id: user.id,
                  email: user.email,
                });
              });
        }
    }, [loading]);

    return (
        <>
            <MatomoProvider value={instance}>
                {children}
            </MatomoProvider>
        </>
    )
}
 
function Layout({ children }) {
    var trigger = false;
    useEffect(() => {
        const script = document.createElement( 'script' );
        script.src = "https://document.boardsi.com/js/profitwell.js";
        script.async = true;
        script.id = "profitwell-js";
        script.setAttribute('data-pw-auth', '0b23483b251b8bceb216504d3dae6d43');

        new Promise(((resolve, reject) => {
            resolve(document.body.appendChild( script ));
        }));

        trigger = true;

        return () => {
            document.body.removeChild( script );
            trigger = true;
        }
    }, [!trigger]);

  return (
        <>
            
            <AuthorizerProvider config={{authorizerURL: 'https://login.boardsi.com/', redirectURL: "http://document.boardsi.com/login/", clientID: '0ffbefe4-0c2c-4b9e-8bdb-c82f7ea28d6b'}}>
                <Apples>
                    {children}
                </Apples>
            </AuthorizerProvider>
            
        </>
  )
}

export default memo(Layout);